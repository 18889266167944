#app-navigation-sidebar{
  background:#fff;
}

#app-navigation-menu {
  height:100%;
  border-right:0px;
}

#app-content-root {
  padding: 24px;
}

#app-content {
  padding: 24px 0px 0px;
}

#app-breadcrumbs {
  margin: 16px 0;
}

.ant-menu-inline-collapsed .menu-item-text{
  opacity: 0;
  -webkit-transition: opacity ease-in-out .2s;
  transition: opacity ease-in-out .2s;
}
#user-menu .user-name{
    color: #FFFF;
}

#user-menu-dropdown{
    margin-top: 20px;
}

#wms-logo {
  color: white;
  font-weight: 300;
  display: inline-block;
}
h1,
h2,
h3,
h4,
h5,
h6,
.ant-card-head-title,
th div {
  font-weight: 400;
}
h1.thin,
h2.thin,
h3.thin,
h4.thin,
h5.thin,
h6.thin {
  font-weight: 300;
}
.tableForm {
  margin-bottom: 10px !important;
}
.search-box.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
  right: 0px;
}
.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item.upc-search-option span {
  display: inline-block;
}
.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item.upc-search-option span.upc {
  width: 30%;
}
.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item.upc-search-option span.description {
  width: 70%;
}
.ant-table-row.disabled {
  background: #fffdf4;
}
.ant-table-row.deleted {
  background: #f3f3f3;
}
.ant-table-wrapper {
  overflow: auto !important;
}
.inline {
  display: inline-block;
}
.flex {
  display: flex;
}
.flex.column {
  flex-direction: column;
}
.flex.justify-center {
  justify-content: center;
}
.flex.justify-space-between {
  justify-content: space-between;
}
.flex.justify-flex-end {
  justify-content: flex-end;
}
.text-center {
  text-align: center;
}
.stats {
  background: #F0F2F5;
  padding: 8px;
  border-radius: 4px;
}
.stat {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  border-bottom: 1px dotted #cecece;
  text-overflow: clip;
  word-break: break-word;
}
.stat-name,
.stat-value {
  display: inline-block;
}
.stat-name {
  font-weight: 600;
  min-width: 100px;
}
.ant-list-item.comment-bubble {
  padding: 8px;
  margin-left: -8px;
  margin-right: -8px;
  border-radius: 4px;
  background: rgba(236, 236, 236, 0.3);
  margin-top: 8px;
}
div .comment-bubble:first-child {
  margin-top: 0;
}
.ant-list-item.comment-bubble h4 {
  font-size: 10pt;
}
.ant-list-item.comment-bubble .ant-list-item-meta {
  margin-bottom: 0;
}
.ant-table-wrapper.drawer-table.form-submission-values tr td:first-child {
  min-width: 200px;
}

.loginContainer{
    position: fixed;
    height: 100%;
    width: 100%;
    background: #2f2f2f; /* DARK_GREY */
    background: url('/login_background.png') repeat center center fixed;
}

.loginContent{
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

h1.logo{
    color: white;
    font-size: 75pt;
    font-weight: 100;
    text-align: center;
    text-shadow: 0px 0px 20px #000;
}
