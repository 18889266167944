.loginContainer{
    position: fixed;
    height: 100%;
    width: 100%;
    background: #2f2f2f; /* DARK_GREY */
    background: url('/login_background.png') repeat center center fixed;
}

.loginContent{
    position: fixed;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

h1.logo{
    color: white;
    font-size: 75pt;
    font-weight: 100;
    text-align: center;
    text-shadow: 0px 0px 20px #000;
}