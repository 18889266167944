#app-navigation-sidebar{
  background:#fff;
}

#app-navigation-menu {
  height:100%;
  border-right:0px;
}

#app-content-root {
  padding: 24px;
}

#app-content {
  padding: 24px 0px 0px;
}

#app-breadcrumbs {
  margin: 16px 0;
}

.ant-menu-inline-collapsed .menu-item-text{
  opacity: 0;
  transition: opacity ease-in-out .2s;
}